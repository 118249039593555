import { Columns } from "../../../../../interfaces/_common";
import globalDataStore from "../../../../../store/_globalData";
import React, { useEffect, useState } from "react";
import protectedApiService from "../../../../../services/_protected_api";
import PrimeDataTable from "../../../../../common/prime_data_table";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import Loader2 from "../../../../../common/loader2";
import LocalstorageStudentData from "../../../../../store/_localstorage_student_data";
export default function NotScheduledStudents() {
  const tablesStructure: Columns[] = [
    {
      data_name: "name",
      header: "Name",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "email",
      header: "EmailID",
      sortable: false,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "contact_no",
      header: "Contact",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "course_name",
      header: "Course",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "operation",
      header: "Operation",
      sortable: true,
      dataFilter: (data: any, key: any) => {
        return (
          <>
            <Link to="/Home/Students/live/Set Student Class" state={data}>
              <Button className="p-btn-primary p-1" aria-label="Facebook">
                <i className="pi pi-calendar-plus p-1"></i>
                <span className="p-1">Set Class</span>
              </Button>
            </Link>
          </>
        );
      },
    },
  ];
  const { allStudents, setAllStudents } = globalDataStore();
  const { getAllStudents, get_junk_users, get_college_user } = protectedApiService();
  const allstudentsstored = LocalstorageStudentData();
  useEffect(() => {
    getData();
  }, []);

  const [allData, setAllData] = useState<any>(null);
  const getData = async () => {
    // if (allStudents) {
    //   let data: any[] = allStudents.filter((x: any) => {
    //     if (!x.assign_class && !x.assign_teacher && x.course_id) {
    //       return x;
    //     }
    //   });
    //   setAllData(data.length ? [...data] : []);
    // } else {
    //   getFromApi();
    // }
    getFromApi();
  };

const remove_junk_data = (element,all_data)=>{
  const n_ary:any = [];
 // console.log(all_data);
  all_data.forEach((ll)=>{
    //console.log(ll["user_id"]);
    if(parseInt(ll["user_id"])!=element){
     n_ary.push(ll);
    }
  })

  return n_ary;
}

const filter_college_data = (element,all_data)=>{
  const n_ary:any = [];
  console.log(all_data);
  all_data.forEach((ll)=>{
    //console.log(ll["user_id"]);
    if(parseInt(ll["user_id"])!=element){
     n_ary.push(ll);
    }
  })

  return n_ary;
}
  const getFromApi = async () => {


    const junk = await get_junk_users();
    const college = await get_college_user();

 //  console.log(junk);
var new_ary = [];
var all_data:any = allstudentsstored;

junk.forEach(element => {
  //console.log(all_data);
all_data = remove_junk_data(element,all_data);

 // console.log(element);

});


if(college!=null){
  college.forEach(ele => {
    all_data = filter_college_data(ele,all_data);
  });
}


const map = new Map();
const stored_data = [];
    for (const item of all_data) {
        if (!map.has(item.user_id)) {
            map.set(item.user_id, true);    // set any value to Map
            stored_data.push(item);


        }
    }  

    //allstudentsstored
    // const res: any = await getAllStudents();
    const res: any = stored_data;
    
    setAllStudents(res);
    let data: any[] = res.filter((x: any) => {
      if (!x.class_assigned && !x.assign_teacher && x.course_id) {
        return x;
      }
    });
    // console.log("not_schedule", data);
    setAllData(data.length ? [...data] : []);
  };

  return (
    <>
      {allData ? (
        <PrimeDataTable
          data={allData}
          structure={tablesStructure}
          title={"Not Scheduled Students"}
          isForStudent
          onRefresh={getFromApi}
          note
          message
          timeline
          options
          view
          filterDropdown={[{ filter: "course_name", header: "Course Name" }]}
        />
      ) : (
        <Loader2 />
      )}
    </>
  );
}
