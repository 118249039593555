import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import protectedApiService from "../../../services/_protected_api";
import commonApiService from "../../../services/_common_api";
import "react-image-picker-editor/dist/index.css";
import { useLocation } from "react-router-dom";
import userState from "../../../store/_userState";
import Loaderme from "../../../common/loader_me";
import { Editor } from "primereact/editor";
export default function Addpayment(){
    const { user } = userState();
    const {getAllCourses} = commonApiService();
    const [courses, setcourses] = useState([]);
    const [default_ld,setdefault_ld] = useState(0);
    const st = {"status":0, "invoice":""}
    const [payment_done, setpayment_done] = useState(st);
    const [due_emi, onTextChange] = useState<string>("");
    const [mail_body, onTextmailbodyChange] = useState<string>("");
    const { add_payment_data, save_invoice_data } = protectedApiService();
useEffect(()=>{
   // console.log(user);
    get_all_courses();
},[])


const setpayment_done_func = ()=>{
    var ar = {"status":0,"invoice":'',"statusCode":0};
    setpayment_done(ar);
}

const get_all_courses = async()=>{
    var data = await getAllCourses();
    var akpk = [];
    const map = new Map();
    for (const item of data) {
        if (!map.has(item.page_name)) {
            map.set(item.page_name, true);    // set any value to Map
            akpk.push(item);


        }
    }
    setcourses(akpk);
    
}

    const init = {
        name: "",
        course_id: "",
        course_price: "",
        deposite_amount: "",
        due_emi: "",
        payment_mode: "",
        email:"",
        next_payment:"",
        mail_body:""
      };
      const [creeds, setCreeds] = useState(init);
      const onValueChange = (val: any) => {
        // console.log(val);
        setCreeds({ ...creeds, ...val });
       // console.log(val);
        // console.log(register);
      };
     // const { add_payment_data } = protectedApiService();
      const payment_data_submit = async (e) => {
        e.preventDefault();
        creeds["user_id"] = user.user_id;
        creeds["due_emi"] = due_emi;
        creeds["mail_body"] = mail_body;
       // console.log(creeds);
        setdefault_ld(1);
        const res: any = await add_payment_data(creeds);
        if(res.statusCode==200){

        }
        if (res.statusCode == 200) {

            setdefault_ld(0);
          toast.success("Created Successfully");
          res["status"] = 1;
          console.log(res);
          setpayment_done(res);
          setCreeds(init);

        get_invoice_save(res.data);

        }else{
          toast.error("Creation failed!");
        }

        // console.log(res);


      };

const get_invoice_save = async(data)=>{
  const resdata = await save_invoice_data(data);
  if(resdata.status==1){
    toast.success("Created Successfully");
  }else{
    toast.error("Error! Please try again later");
  }
}


    return(
        <>

{(()=>{
    if(default_ld==1){
        return(
            <>
            <Loaderme />
            </>
        )
    }
})()}
    {/* <Loaderme /> */}
{(()=>{
    if(payment_done['status']==1){
return(
    <>
    <div className="alert alert-success">Payment Successfully Received. <a className="btn btn-primary" href={payment_done["data"]['file']['url']} download>Download Invoice</a></div>
    </>
)
    }
})()}


             <div className=" mt-3">
        <h4>Add Payment</h4>

       <form method="post" onSubmit={(e)=>{payment_data_submit(e)}}>

        <div className="card shadow  p-4">
          <div className="row mx-3">
            <div className="col-sm-6 ">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Name
                </label>

                <input
                  type="name"
                  className="form-control"
                  name="name"
                  id="name"
                  value={creeds.name}
                  aria-describedby="namelHelp"
                  placeholder="John Doe"
                  required
                  //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                  onKeyUp={()=>{setpayment_done_func()}}
                  onChange={(e) =>
                    onValueChange({
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col-sm-6 ">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Email
                </label>

                <input
                  type="email"
                  className="form-control"
                  name="email"
                  id="email"
                  value={creeds.email}
                  aria-describedby="namelHelp"
                  placeholder="mail@example.com"
                  required
                  //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                  onChange={(e) =>
                    onValueChange({
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Course Name
                </label>
                <select className="form-control" required name="course_id" id="course_id"  onChange={(e) =>
                    onValueChange({
                      [e.target.name]: e.target.value,
                    })
                  }>
                    <option value="">Select One</option>
                    {courses.map((co, index)=>{
                        return(
                            <>
                            <option value={co.course_id}>{co.page_name}</option>
                            </>
                        )
                    })}
                    
                </select>
                {/* <input
                  type="text"
                  className="form-control"
                  name="email"
                  id="email"
                  value={creeds.email}
                  aria-describedby="namelHelp"
               
                  //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                  onChange={(e) =>
                    onValueChange({
                      [e.target.name]: e.target.value,
                    })
                  }
                /> */}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                 Course Price
                </label>
                <input
                  type="number"
                  maxLength={10}
                  className="form-control"
                  name="course_price"
                  id="course_price"
                  value={creeds.course_price}
                  aria-describedby="namelHelp"
                  placeholder="Enter Amount"
                  required
                  //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                  onChange={(e) =>
                    onValueChange({
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Deposit Amount
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="deposite_amount"
                  id="deposite_amount"
                  value={creeds.deposite_amount}
                  aria-describedby="namelHelp"
                  placeholder="Deposit Amount"
                  
                  //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                  onChange={(e) =>
                    onValueChange({
                      [e.target.name]: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Due/EMI Amount (Max 15 lines, Max 2 white Space)
                </label>
                <Editor
                  style={{ height: "180px" }}
                  
                  onTextChange={(e: any) => onTextChange(e.htmlValue)}
                />
              </div>
            </div>
            <div className="col-sm-6 ">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Next Payment Date
                </label>

                <input
                  type="date"
                  className="form-control"
                  name="next_payment"
                  id="next_payment"
                  value={creeds.next_payment}
                  aria-describedby="namelHelp"
                  placeholder="John Doe"
                  
                  //   onBlur={(e) => onBlur({ [e.target.name]: e.target.value })}
                  onChange={(e) =>
                    onValueChange({
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Payment Mode
                </label>
                <select className="form-control" required  name="payment_mode"
                  id="payment_mode" onChange={(e) =>
                    onValueChange({
                      [e.target.name]: e.target.value,
                    })
                  }>
                    <option value="">Select Type</option>
                    <option value="Bank">Bank</option>
                    <option value="RazorPay">RazorPay</option>
                    <option value="Cash">Cash</option>
                    <option value="Online">Online</option>
                </select>
              </div>
            </div>

            <div className="col-sm-12">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Mail Body
                </label>
                <Editor
                  style={{ height: "180px" }}
                  
                  onTextChange={(e: any) => onTextmailbodyChange(e.htmlValue)}
                />
              </div>
            </div>
          
          </div>
          <div className="flex-start p-3 mx-3">
            <button className="btn btn-primary">
              {" "}
              Add Payment
            </button>
          </div>
        </div>
        </form>
      </div>
        </>
    )
}