import React, { useEffect, useState, useCallback } from "react";
import moment from "moment";
import PrimeDataTable from "../../../common/prime_data_table";
import { Columns } from "../../../interfaces/_common";
import protectedApiService from "../../../services/_protected_api";
import userState from "../../../store/_userState";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import FullCalendar, {
  DateSelectArg,
  EventApi,
  EventClickArg,
  EventInput,
  EventContentArg,
  EventRemoveArg,
} from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useLocation } from "react-router-dom";

export default function Schedule() {
  const location: any = useLocation();
  const faculty: any = location.state;

  const { user } = userState();

  //console.log(user);

  //console.log(faculty);
  var userr: any = [];
  if (faculty == null) {
    userr = user;
  } else {
    userr = faculty;
  }

  const {
    getFacultyDetails,
    get_faculty_tming,
    student_present_absent_data_submit,
    class_complete_faculty,
    addlivelink,
    addrecordedlink,
    upload_class_note_add,
  } = protectedApiService();
  const [allData, setAllData] = useState(null);
  const [faculty_timing, setfacultytiming] = useState(null);
  const [renderloading, setRenderLoading] = useState<boolean>(true);
  const [initialEvents, setInitialEvents] = useState<EventInput[]>([]);
  const [currentEvents, setCurrentEvents] = useState<EventApi[]>([]);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [faculty_details, setfaculty_details] = useState("");

  const [all_data, setall_data] = useState(null);

  const [link, setlink] = useState("");
  const [recorded, setrecorded] = useState("");
  const [cls_note, setcls_note] = useState([]);
  const [note_link, setnote_link] = useState("");

  const [all_fac, setall_fac] = useState<any>(null);

  const setclass_note = (e: any) => {
    setcls_note(e);
  };

  const livelinkadd = (e: any) => {
    e.preventDefault();
    // console.log(all_data);
    const datal: any = all_data;
    var formdata = new FormData();
    formdata.append("faculty_timing_id", datal[0].faculty_timing_id);
    formdata.append("link", link);

    const sub: any = addlivelink(formdata);
    // if(sub==1){
    //   toast.success("Added successfully");
    // }else{
    //   toast.error("Error");
    // }

    toast.success("Added successfully");
  };

  const recordedlinkadd = (e: any) => {
    e.preventDefault();
    const datal: any = all_data;
    var formdata = new FormData();
    formdata.append("faculty_timing_id", datal[0].faculty_timing_id);
    formdata.append("recorded_link", recorded);
    const sub: any = addrecordedlink(formdata);
    toast.success("Added successfully");
  };

  const upload_class_note = (e: any) => {
    e.preventDefault();
    const datal: any = all_data;
    const cls: any = cls_note;
    var fd = new FormData();
    fd.append("faculty_timing_id", datal[0].faculty_timing_id);
    fd.append("note_docs", cls, cls.name);

    const up_cls = upload_class_note_add(fd);
    toast.success("Added successfully");

    // console.log(cls.name);
  };

  const tablesStructure: Columns[] = [
    {
      data_name: "name",
      header: "Student Name",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "email",
      header: "Email",
      sortable: false,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "course_name",
      header: "Course Name",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "contact_no",
      header: "Number",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },

    {
      data_name: "start_time",
      header: "Start Time",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "end_time",
      header: "End Time",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "mode",
      header: "Mode",
      sortable: true,
      dataFilter: (data: any, key: any) => {
        if (data[key] == 0) return <div className="text-success">online</div>;
        if (data[key] == 1) return <div className="text-danger">offline</div>;
        if (data[key] == "online")
          return <div className="text-success">online</div>;
        if (data[key] == "offline")
          return <div className="text-danger">offline</div>;
        if (data[key] == 2)
          return <div className="text-info">online & offline</div>;
        return <div className="text-gray">Not Specified</div>;
      },
    },
    {
      data_name: "student_cls",
      header: "Class",
      sortable: true,
      dataFilter: (data: any, key: any) => {
        if (data["student_cls"] == null) {
          return (
            <>
              <span
                className="btn btn-success"
                onClick={() => {
                  get_present_absent(data, 1);
                }}
              >
                Present
              </span>
              &nbsp;
              <span
                className="btn btn-danger"
                onClick={() => {
                  get_present_absent(data, 0);
                }}
              >
                Absent
              </span>
            </>
          );
        } else if (data["student_cls"] == 1) {
          return (
            <>
              <span className="btn btn-success" aria-disabled="true">
                Present
              </span>
            </>
          );
        } else if (data["student_cls"] == 0) {
          return (
            <>
              <span className="btn btn-danger" aria-disabled="true">
                Absent
              </span>
            </>
          );
        }
      },
    },
  ];

  useEffect(() => {
    get_faculty_details();
  }, []);

  const handleEvents = useCallback((events: EventApi[]) => {
    setCurrentEvents(events);
    // events.map((x: any) => {
    // //console.log(x);
    // });
  }, []);

  const [selectInfoState, setSelectInfoState] = useState<any>(null);
  const handleDateSelect = useCallback((selectInfo: DateSelectArg) => {
    handleShow();
    setSelectInfoState(selectInfo);
    let calendarApi = selectInfo.view.calendar;
    calendarApi.unselect();
  }, []);

  const get_faculty_details = async () => {
    const details = await getFacultyDetails(userr.user_id);
    //console.log(details);
    setall_fac(details);
    setfaculty_details(details["faculty_details"]["name"]);
    const faculty_timing = details["faculty_timing"];

    // const filtered_course_data = details.faculty_timing.filter(
    //     (item: any) => item.course_id == student.course_id
    //   );
    const eventInit: EventInput[] = faculty_timing.map((fac: any) => {
     // console.log(fac);
      let timing: EventInput = {
        id: Math.random() * 100 - 15 + 15 + "",
        start: `${fac.date.replace(/T.*$/, "")}T${fac.start_time}:00+05:30`,
        end: `${fac.date.replace(/T.*$/, "")}T${fac.end_time}:00+05:30`,
        title: fac.topics || fac.name,
        display: `${fac.start_time.replace(
          /T.*$/,
          ""
        )} - ${fac.end_time.replace(/T.*$/, "")}`,
        date: fac.date,
        event_id: fac.faculty_timing_id,
        mode: fac.mode,
        extra: fac,
      };
      //console.log(timing);
      return timing;
    });
    //console.log(eventInit);
    setInitialEvents(eventInit);
    // //console.log("Init", eventInit);
    setRenderLoading(false);

    //console.log(initialEvents);
  };

  const renderEventContent = (eventContent: EventContentArg) => {
    const content = eventContent.event.extendedProps.extra;

    // const res: any =  getFacultyDetails(content.faculty_id);
    //console.log(all_fac);
    
     var aok: any = "";
        
    all_fac.faculty_courses.forEach((ek: any) => {
      if (ek.course_id == content.course) {
        aok = ek.course_name;
      }
    });
   // console.log(eventContent);
    return (
      <div
        className="calender-data  "
        style={{
          backgroundColor:
            content.class_completed == 0
              ? content.mode == "0"
                ? "#00f210"
                : content.mode == "1"
                ? "#00def2"
                : "#f2c200"
              : "#FF0000",
        }}
      >
        <>
          <span>
            {content.topics?(<>{content.topics}</>):(<>{aok}</>)} {<><br /></>}
            {moment(eventContent.event.start).format("h:mm  a")} -{" "}
            {moment(eventContent.event.end).format("h:mm  a")}
          </span>
        </>
      </div>
    );
  };

  const get_data_submit_retrieve = async (event_id: any) => {
    const timing_dataa: any = await get_faculty_tming(event_id);
    // console.log(timing_dataa);
    setall_data(timing_dataa);
  };

  const handleEventClick = async (e: any) => {
 //   console.log(e.event.extendedProps.extra);
    setDeleteModalShow(true);
    get_data_submit_retrieve(e.event.extendedProps.event_id);

    if (e.event._def.extendedProps.extra.link == null) {
      setlink("");
      setrecorded("");
      setnote_link("");
    } else {
      setlink(e.event._def.extendedProps.extra.link);
      setrecorded(e.event._def.extendedProps.extra.recorded_link);
      // var note_link = "https://management.dataspace"
      setnote_link(e.event._def.extendedProps.extra.note_docs);
    }

    // async (clickInfo: EventClickArg) => {
    //   //console.log("Extened", clickInfo.event.extendedProps);

    //   setClickInfoEvent(clickInfo);
    //   setSelectedEvent(clickInfo.event);
    //   const extra: any = clickInfo.event.extendedProps.extra;
    //   const creeds: any = {
    //     schedule_date: extra.date,
    //     student_user_id: student.user_id,
    //     user_id: extra.user_id,
    //     faculty_timing_id: extra.faculty_timing_id,
    //     course_id: extra.course_id,
    //   };
    //   const res: any = await getStudentFacultyTiming(creeds);
    //   let newTiming: any[] = [];
    //   function getScheduledTiming(arr1: any, arr2: any, key: string) {
    //     let newTiming: any[] = [];
    //     arr1.map((one: any) => {
    //       arr2.map((two: any) => {
    //         if (two[key] == one[key]) {
    //           newTiming.push({ ...one, ...two });
    //         }
    //       });
    //     });
    //     return newTiming;
    //   }
    //   console.log(res);

    //   setTiming({
    //     scheduledTiming: [
    //       ...getScheduledTiming(
    //         res.timing,
    //         student_classes,
    //         "faculty_timing_id"
    //       ),
    //     ],
    //     notscheduledTiming: [...res.timing],
    //     students: res.students,
    //   });
    //   setDeleteModalShow(true);
    // },
    // [selectedEvent, clickInfoEvent, timing]
    //);
  };

  const get_present_absent = async (data: any, status: any) => {
   // console.log(data);
    var ddt;
    if (status == 1) {
      ddt = "Are you sure to present this class?";
    } else {
      ddt = "Are you sure to absent this class?";
    }
    var al = window.confirm(ddt);

    if (al === true) {
      var d = {
        student_classes_id: data.student_classes_id,
        student_cls: status,
      };

      const submit: any = await student_present_absent_data_submit(d);

      if (submit == 1) {
        get_data_submit_retrieve(data.faculty_timing_id);
      }
    }
  };

  const complete_class = async (data: any) => {
    //console.log(data);
    const conf = window.confirm("Are you sure?");
    if (conf == true) {
      const class_com = await class_complete_faculty(data.faculty_timing_id);
      if (class_com == 1) {
        toast.success("Class Successfully completed");
        get_data_submit_retrieve(data.faculty_timing_id);
      }
    }
  };

  return (
    <>
      {!renderloading ? (
        <div className="mt-3" style={{ paddingBottom: "20vh" }}>
          <div className="flex-between">
            <div className="heading col-sm-4">Set Student Class Schedule</div>
          </div>
          <div className="card p-4 shadow mt-3">
            {user.role==7?(
              <>
               <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                start: "today prev next",
                center: "title",
                end: "dayGridMonth timeGridWeek timeGridDay",
              }}
              height={"70vh"}
              initialView="dayGridMonth"
              selectable={true}
              editable={false}
              initialEvents={initialEvents}
              eventsSet={handleEvents}
              select={handleDateSelect}
              // eventClick={handleEventClick}
              eventColor={"transparent"}
              eventContent={renderEventContent}
              //eventRemove={afterDeleteEvent}
            />
              </>
            ):(
              <>
               <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                start: "today prev next",
                center: "title",
                end: "dayGridMonth timeGridWeek timeGridDay",
              }}
              height={"70vh"}
              initialView="dayGridMonth"
              selectable={true}
              editable={false}
              initialEvents={initialEvents}
              eventsSet={handleEvents}
              select={handleDateSelect}
              eventClick={handleEventClick}
              eventColor={"transparent"}
              eventContent={renderEventContent}
              //eventRemove={afterDeleteEvent}
            />
              </>
            )}
           
            <div className="mt-4 ml-2 mx-auto d-flex">
              <div className="flex-start mr-2">
                <span
                  className="color-box"
                  style={{
                    backgroundColor: "#00f210",
                  }}
                ></span>
                <p>Online</p>
              </div>
              <div className="flex-start mr-2">
                <span
                  style={{
                    backgroundColor: "#00def2",
                  }}
                  className="color-box"
                ></span>
                <p>Offline</p>
              </div>
              <div className="flex-start mr-2">
                <span
                  style={{
                    backgroundColor: "#f2c200",
                  }}
                  className="color-box"
                ></span>
                <p>Online And Offline / Not Specified</p>
              </div>
              <div className="flex-start mr-2">
                <span
                  style={{
                    backgroundColor: "#FF0000",
                  }}
                  className="color-box"
                ></span>
                <p>Completed</p>
              </div>
            </div>
          </div>
          <Modal
            fullscreen={true}
            aria-labelledby="example-custom-modal-styling-title"
            show={deleteModalShow}
            onHide={() => setDeleteModalShow(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {faculty_details}

                {/* {selectedEvent && selectedEvent.extendedProps.extra.date} */}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <PrimeDataTable
                data={all_data || []}
                structure={tablesStructure}
                title={"All Data"}

                // onRefresh={get_events}

                //options
              />
              {/* {timing.notscheduledTiming?.length ? (
                <AddClassTable
                  student_classes={timing.notscheduledTiming}
                  scheduled_timing={timing.scheduledTiming}
                  assigned_students={timing.students}
                  student={student}
                  title="Available Schedule Time"
                />
              ) : (
                <></>
              )} */}

              {(() => {
                if (all_data != null) {
                  var ak: any = all_data;

                  if (ak.length > 0) {
                    if (ak[0].class_completed == "0") {
                      return (
                        <>
                          <button
                            onClick={() => complete_class(ak[0])}
                            className="btn btn-success btn-sm"
                          >
                            Click to Complete Class
                          </button>
                          <br></br>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <span
                            style={{
                              color: "red",
                              fontWeight: "bold",
                              fontSize: "21px",
                            }}
                          >
                            Class Completed
                          </span>
                        </>
                      );
                    }
                  } else {
                    return (
                      <>
                        <span
                          style={{
                            color: "red",
                            fontWeight: "bold",
                            fontSize: "21px",
                          }}
                        >
                          Class Completed
                        </span>
                      </>
                    );
                  }
                }
              })()}

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <h4>
                    Live Video Link: <a href={link}>{link}</a>
                  </h4>
                  <h4>
                    Recorded Video Link: <a href={recorded}>{recorded}</a>
                  </h4>
                  <h4>
                    Class Note:{" "}
                    {(() => {
                      if (note_link != null) {
                        return <>Note Added</>;
                      }
                    })()}
                  </h4>
                </div>
                <div>
                  <div className="sdt" style={{ margin: "10px 0" }}>
                    <input
                      type="text"
                      placeholder="Enter Live link"
                      value={link}
                      onChange={(e) => setlink(e.target.value)}
                    />{" "}
                    <button
                      className="btn btn-primary"
                      onClick={(e) => {
                        livelinkadd(e);
                      }}
                    >
                      Add
                    </button>
                    <br></br>
                  </div>

                  <div className="sdt" style={{ margin: "10px 0" }}>
                    <input
                      type="text"
                      placeholder="Enter Recorded Video link"
                      value={recorded}
                      onChange={(e) => setrecorded(e.target.value)}
                    />{" "}
                    <button
                      className="btn btn-primary"
                      onClick={(e) => {
                        recordedlinkadd(e);
                      }}
                    >
                      Add Recorded Link
                    </button>
                  </div>

                  <div className="sdt" style={{ margin: "10px 0" }}>
                    <form>
                      {/* <span>Upload Class Note</span> */}
                      <input
                        type="file"
                        placeholder="Enter Recorded Video link"
                        onChange={(e: any) => setclass_note(e.target.files[0])}
                        accept=".pdf"
                      />{" "}
                      <button
                        className="btn btn-primary"
                        onClick={(e) => {
                          upload_class_note(e);
                        }}
                      >
                        Add Note
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                onClick={() => setDeleteModalShow(false)}
                className="btn btn-info btn-sm"
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      ) : (
        <>Loading.....</>
      )}
    </>
  );
}
