import axios from "axios";
import globalDataStore from "../store/_globalData";
import userState, { decrypt } from "../store/_userState";
import AuthService from "./_auth";
import LocalstorageStudentData from "../store/_localstorage_student_data";
export default function protectedApiService() {
  const _https = axios.create({
    baseURL: "https://dataspaceacademymanagement.in/api",
  });
  const { logout } = AuthService();
  const { accessToken } = userState();
  // // console.log(decrypt(localStorage.getItem("access") || ""), accessToken);
  let authHeader = {
    headers: {
      authentication: accessToken,
    },
  };

  let invoice_auth = {
    headers:{
      Authorization:"0eeb358ed7170e2bff4f0880fdc2673de3846f5a"
    }
  }
  // const createForm = (creeds: any) => {
  //   let formData = new FormData();
  //   Object.keys(creeds).map((item) => formData.append(item, creeds[item]));
  //   return formData;
  // };
  // // console.log(authHeader);
  const {
    setAllAdmins,
    setAllFaculty,
    setAllStudents,
    setAllMarketing,
    setAllCourses,
  } = globalDataStore();
  const getAllStudents = async () => {
    let authHeaderr = {
      headers: {
        authentication: "$2y$10$TeaPa6DRScVaNqLSLSycVessnIRZT2pBF8PO9AxpQ6bScT3D45V8e",
      },
    };
    try {
      const res = await _https.get("/student_course_all_report", authHeader);
      // // console.log(res.data);
      setAllStudents(res.data);
      return res.data;
    } catch (e: any) {
      if (e.msg === "you are not an authorised user") {
        logout();
      }
      //  // console.log("details", e);
    }
  };


const get_student_add_checker = async(id:any)=>{
  try {
    const res = await _https.get("/student_course_all_report_limit/"+id, authHeader);
    // // console.log(res.data);
    setAllStudents(res.data);
    return res.data;
  } catch (e: any) {
    if (e.msg === "you are not an authorised user") {
      logout();
    }
    //  // console.log("details", e);
  }
}

  const getAllStudentsReminder = async () => {
    try {
      const res = await _https.get(
        "/get_student_class_complete_reminder",
        authHeader
      );
      // // console.log(res.data);
      return res.data;
    } catch (e: any) {
      if (e.msg === "you are not an authorised user") {
        logout();
      }
      //  // console.log("details", e);
    }
  };
  const getAllAdmins: any = async () => {
    try {
      const res = await _https.get("/get_admin", authHeader);
      // // console.log(res.data);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      setAllAdmins(res.data);
      return res.data;
    } catch (e: any) {
      if (e.msg === "you are not an authorised user") {
      }
      /// // console.log(e);
    }
  };
  const getAllFaculty = async () => {
    try {
      const res = await _https.get("/get_faculty_report_all", authHeader);
      // // console.log(res.data);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      } else {
        setAllFaculty(res.data);
      }
      return res.data;
    } catch (e: any) {
      // // console.log("error", e);
    }
  };
  const getAllMarketing = async () => {
    try {
      const res = await _https.get("/get_marketing_tean", authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      setAllMarketing(res.data);
      // // console.log(res.data);
      return res.data;
    } catch (e) {
      //// console.log(e);
    }
  };
  const getAllSales = async () => {
    try {
      const res = await _https.get("/get_marketing_tean", authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      setAllMarketing(res.data);
      // // console.log(res.data);
      return res.data;
    } catch (e) {
      //// console.log(e);
    }
  };
  const getClassSummery = async () => {
    try {
      const res_one = await _https.get("/today_all_reports", authHeader);
      if (res_one.data?.msg === "you are not an authorised user") {
      }
      // // console.log(res.data);
      if (res_one.data) {
        // // console.log({ ...res_one.data, ...res_two.data });
        return { ...res_one.data };
      }
    } catch (e) {
      //// console.log(e);
    }
  };

  const getAdminAllReport = async () => {
    try {
      const res = await _https.get("/admin_all_reports", authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      // // console.log(res.data);
      return res.data;
    } catch (e) {
      //// console.log(e);
    }
  };
  const getAllNews = async () => {
    try {
      const res = await _https.get("/get_all_news", authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      // // console.log(res.data);
      return res.data;
    } catch (e) {
      //// console.log(e);
    }
  };
  const getFacultyDetails = async (id: any) => {
    try {
      const res = await _https.get("/get_faculty_details/" + id, authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      // // console.log(res.data);
      return res.data;
    } catch (e) {
      //// console.log(e);
    }
  };
  const getUserDetails = async (id: any) => {
    try {
      const res = await _https.get("/get_user_details/" + id, authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      // // console.log(res.data);
      return res.data;
    } catch (e) {
      //// console.log(e);
    }
  };
  const getStudentNotes = async (id: any) => {
    try {
      const res = await _https.get("/get_student_note/" + id, authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      // // console.log(res.data);
      return res.data;
    } catch (e) {
      //// console.log(e);
    }
  };
  const getStudentMessages = async (id: any) => {
    try {
      const res = await _https.get("/student-message/" + id, authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      // // console.log(res.data);
      return res.data;
    } catch (e) {
      //// console.log(e);
    }
  };
  const getStudentClasses = async (id: any) => {
    try {
      const res = await _https.get("/student_class_get/" + id, authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      // // console.log(res.data);
      return res.data;
    } catch (e) {
      //// console.log(e);
    }
  };
  const getFacultyTimingAll = async () => {
    try {
      const res = await _https.get("/get_faculty_details", authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      // // console.log(res.data);
      return res.data;
    } catch (e) {
      //// console.log(e);
    }
  };
  const getJobsAll = async () => {
    try {
      const res = await _https.get("/get_jobs", authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      // // console.log(res.data);
      return res.data;
    } catch (e) {
      //// console.log(e);
    }
  };
  const getAllAppliedJobs = async () => {
    try {
      const res = await _https.get("/get_user_job_apply", authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      // // console.log(res.data);
      return res.data;
    } catch (e) {
      //// console.log(e);
    }
  };

  const getStudentFacultyTiming = async (creeds: any) => {
    try {
      const res = await _https.post("/get_faculty_tm", creeds, authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      // // console.log(res.data);
      return res.data;
    } catch (e) {
      //// console.log(e);
    }
  };
  const getNotifications = async () => {
    try {
      const res = await _https.get(
        "/get_notification_data/" + accessToken,
        authHeader
      );
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      // // console.log(res.data);
      return res.data;
    } catch (e) {
      //// console.log(e);
    }
  };

  const getMessages = async () => {
    try {
      const res = await _https.get(
        "/student-message/" + accessToken,
        authHeader
      );
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      // // console.log(res.data);
      return res.data;
    } catch (e) {
      //// console.log(e);
    }
  };

  ////// all protected post requests
  const postUpdateJob = async (creeds: any) => {
    try {
      // let formData = new FormData();
      // Object.keys(creeds).map((item) => formData.append(item, creeds[item]));
      // const res = await _https.post(
      //   "/update_job_student",
      //   formData,
      //   authHeader
      // );
      // return res.data;

      const res = await _https.post(
        "/update_job_student",
        creeds,
        authHeader
      );
      return res.data;

    } catch (e) {}
  };
  const postAddJob = async (creeds: any) => {
    try {
      // let formData = new FormData();
      // Object.keys(creeds).map((item) => formData.append(item, creeds[item]));
      // const res = await _https.post("/add_job_student", formData, authHeader);
      // return res.data;
      const res = await _https.post("/add_job_student", creeds, authHeader);
      return res.data;
    } catch (e) {}
  };
  const postAddNotes = async (creeds: any) => {
    try {
      let formData = new FormData();
      Object.keys(creeds).map((item) => formData.append(item, creeds[item]));
      const res = await _https.post("/add_note_user", formData, authHeader);
      return res.data;
    } catch (e) {}
  };
  const postAssignProject = async (creeds: any) => {
    try {
      let formData = new FormData();
      Object.keys(creeds).map((item) => formData.append(item, creeds[item]));
      const res = await _https.post(
        "/add_teacher_assign",
        formData,
        authHeader
      );
      return res.data;
    } catch (e) {}
  };
  const postAddFacultyTiming = async (creeds: any) => {
    try {
      const res = await _https.post("/faculty_add_timing", creeds, authHeader);
      return res.data;
    } catch (e) {}
  };
  const postAddMarketing = async (creeds: any) => {
    try {
      let formData = new FormData();
      Object.keys(creeds).map((item) => formData.append(item, creeds[item]));
      const res = await _https.post("/marketing_add", formData, authHeader);
      return res.data;
    } catch (e) {}
  };
  const postAddMessage = async (creeds: any) => {
    try {
      const res = await _https.post(
        "/add_user_message_data",
        creeds,
        authHeader
      );
      return res.data;
    } catch (e) {}
  };
  const postGiveStudentClass = async (creeds: any) => {
    try {
      const res = await _https.post("/student_class_set", creeds, authHeader);
      return res.data;
    } catch (e) {}
  };
  const postAddAdmin = async (creeds: any) => {
    try {
      let formData = new FormData();
      Object.keys(creeds).map((item) => formData.append(item, creeds[item]));
      const res = await _https.post("/add_admin", formData, authHeader);
      return res.data;
    } catch (e) {}
  };
  const postAddNewSchedule = async (creeds: any) => {
    try {
      const res = await _https.post(
        "/add_available_scheduled_link",
        creeds,
        authHeader
      );
      return res.data;
    } catch (e) {}
  };
  const postAddFaculty = async (creeds: any) => {
    try {
      let formData = new FormData();
      Object.keys(creeds).map((item) => formData.append(item, creeds[item]));
      const res = await _https.post("/add_faculty", formData, authHeader);
      return res.data;
    } catch (e) {}
  };
  const postAddNews = async (creeds: any) => {
    try {
      const res = await _https.post("/add_news_details", creeds, authHeader);
      return res.data;
    } catch (e) {}
  };

  /////////////delete requests
  const postDeleteFacultyTiming = async (creeds: any) => {
    try {
      const res = await _https.get(
        `/delete_faculty_timing/${creeds}`,
        authHeader
      );
      return res.data;
    } catch (e) {}
  };
  const deleteOneNews = async (creeds: any) => {
    try {
      const res = await _https.get(`/delete_news/${creeds}`, authHeader);
      return res.data;
    } catch (e) {}
  };
  const deleteJob = async (creeds: any) => {
    try {
      const res = await _https.get(`/delete_jobs/${creeds}`, authHeader);
      return res.data;
    } catch (e) {}
  };
  const deleteUser = async (creeds: any) => {
    try {
      const res = await _https.get(`/delete_user/${creeds}`, authHeader);
      return res.data;
    } catch (e) {}
  };
  const deleteStudentClass = async (creeds: any) => {
    try {
      const res = await _https.get(
        `/delete_student_class/${creeds}`,
        authHeader
      );
      return res.data;
    } catch (e) {}
  };

  ////////////// update
  const postUpdateCourseCompleteStatus = async (creeds: any) => {
    try {
      const res = await _https.get(
        `/course_completed_status_change/${creeds}`,
        authHeader
      );
      return res.data;
    } catch (e) {}
  };
  const postUpdateProjectCompleteStatus = async (creeds: any) => {
    try {
      const res = await _https.post(
        `/course_student_interview`,
        creeds,
        authHeader
      );
      return res.data;
    } catch (e) {}
  };

  const updateUserDetails = async (creeds: any) => {
    try {
      const res = await _https.post(
        `/delete_faculty_timing `,
        creeds,
        authHeader
      );
      return res.data;
    } catch (e) {}
  };

  const getFacultyevents = async () => {
    try {
      const res = await _https.get("/get_live_link_data_param", authHeader);
      // // console.log(res.data);
      //setAllStudents(res.data);
      return res.data;
    } catch (e: any) {
      if (e.msg === "you are not an authorised user") {
        logout();
      }
      //  // console.log("details", e);
    }
  };

  const get_student_remarks = async (user_id: any) => {
    try {
      const res = await _https.get(
        "/get_all_students_feedback/" + user_id,
        authHeader
      );
      return res.data;
    } catch (e: any) {
      if (e.msg === "you are not an authorised user") {
        logout();
      }
    }
  };

  const get_faculty_tming = async (id: any) => {
    // // console.log(id);
    try {
      const res = await _https.get("get_faculty_tming/" + id, authHeader);
      //// console.log(res.data);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      // // console.log(res.data);
      return res.data;
    } catch {}
  };

  const get_faculty_upcoming_classes = async (id: any) => {
    try {
      const res = await _https.get(
        "get_faculty_upcomming_classes/" + id,
        authHeader
      );
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      // // console.log(res.data);
      return res.data;
    } catch {}
  };

  const get_faculty_project = async (id: any) => {
    try {
      const res = await _https.get("faculty_project_assign/" + id, authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      // // console.log(res.data);
      return res.data;
    } catch {}
  };

  const faculty_project_assign_accepted = async (id: any) => {
    try {
      const res = await _https.get(
        "faculty_project_assign_final/" + id,
        authHeader
      );
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      // // console.log(res.data);
      return res.data;
    } catch {}
  };

  const student_present_absent_data_submit = async (data: any) => {
    try {
      const res = await _https.post("class_present_absent", data, authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      // // console.log(res.data);
      return res.data;
    } catch {}
  };

  const get_student_all_courses = async () => {
    try {
      const res = await _https.get("get_courses");
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      // // console.log(res.data);
      return res.data;
    } catch {}
  };

  const getAppliedJob = async () => {
    try {
      const res = await _https.get("get_user_job_apply", authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      // // console.log(res.data);
      return res.data;
    } catch {}
  };

  const course_add = async (data: any) => {
    try {
      const res = await _https.post("add_course", data, authHeader);

      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      // // console.log(res.data);
      return res.data;
    } catch (e) {
      //// console.log(e);
    }
  };

  const course_update = async (data: any) => {
    try {
      const res = await _https.post("update_course", data, authHeader);

      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      // // console.log(res.data);
      return res.data;
    } catch (e) {
      //// console.log(e);
    }
  };

  const course_delete = async (id: any) => {
    try {
      const res = await _https.get("delete_course/" + id, authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      // // console.log(res.data);
      return res.data;
    } catch {}
  };

  const stud_update_details = async (data: any) => {
    try {
      const res = await _https.post("stud_update_details", data, authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      return res.data;
    } catch {}
  };

  const fac_update_details = async (data: any) => {
    try {
      const res = await _https.post("fac_update_details", data, authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      return res.data;
    } catch {}
  };

  const updateStudentDetails = async (creeds: any) => {
    try {
      let formData = new FormData();
      Object.keys(creeds).map((item) => formData.append(item, creeds[item]));
      const res = await _https.post(
        "update_student_details",
        formData,
        authHeader
      );
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      return res.data;
    } catch {}
  };

  const update_student_details = () => {};

  const getStudentStatus_all = async () => {
    try {
      const res = await _https.get("student_course_all_status", authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      return res.data;
    } catch {}
  };

  const getclassreport = async () => {
    try {
      const res = await _https.get("get_class_details_report", authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      return res.data;
    } catch {}
  };

  const referredusers = async () => {
    try {
      const res = await _https.get("refered_user_get_data", authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      return res.data;
    } catch {}
  };

  const branches = async () => {
    try {
      const res = await _https.get("get_branches", authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      return res.data;
    } catch {}
  };

  const get_councellors = async () => {
    try {
      const res = await _https.get("get_councellors", authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      return res.data;
    } catch {}
  };

  const branch_add = async (data: any) => {
    const res = await _https.post("add_branch", data, authHeader);
    return res.data;
  };

  const councellor_add = async (data: any) => {
    const res = await _https.post("add_councellor", data, authHeader);
    return res.data;
  };
  const branch_delete = async (id: any) => {
    const res = await _https.get("branches_delete/" + id, authHeader);
    return res.data;
  };

  const councellor_delete = async (id: any) => {
    const res = await _https.get("councellor_delete/" + id, authHeader);
    return res.data;
  };

  const get_schedule_list_data = async () => {
    const res = await _https.get(
      "get_available_schedule_link_data_filter_date",
      authHeader
    );
    return res.data;
  };

  const delete_available_schedule = async (id: any) => {
    try {
      const res = await _https.get(
        "delete_available_scheduled_data/" + id,
        authHeader
      );
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      return res.data;
    } catch {}
  };

  const updateNewSchedule = async (data: any) => {
    const res = await _https.post(
      "update_available_scheduled_link",
      data,
      authHeader
    );
    if (res.data?.msg === "you are not an authorised user") {
      logout();
    }
    return res.data;
  };

  const class_complete_faculty = async (data: any) => {
    try {
      const res = await _https.get("class_completed/" + data, authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      return res.data;
    } catch {}
  };

  const addlivelink = async (data: any) => {
    try {
      var res = await _https.post("update_link_data", data, authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      return res.data;
    } catch {}
  };

  const addrecordedlink = async (data: any) => {
    try {
      var res = await _https.post(
        "update_recorded_link_data",
        data,
        authHeader
      );
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      return res.data;
    } catch {}
  };

  const upload_class_note_add = async (data: any) => {
    try {
      var res = await _https.post(
        "studentfacultyschedule_note",
        data,
        authHeader
      );
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      return res.data;
    } catch {}
  };

  const CourseCompletedStudents = async (data: any) => {
    try {
      var res = await _https.get(
        "course_completed_status_change/" + data,
        authHeader
      );
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      return res.data;
    } catch {}
  };

  const get_course_incompleted_student = async () => {
    try {
      var res = await _https.get(
        "get_all_students/1?course_completed=0",
        authHeader
      );
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      return res.data;
    } catch {}
  };

  const interview_completed = async (id: any) => {
    try {
      var res = await _https.get("turn_into_interview/" + id, authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      return res.data;
    } catch {}
  };

  const student_view_class = async (id: any) => {
    try {
      var res = await _https.get("student_class_get/" + id, authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      return res.data;
    } catch {}
  };
  const getresourcesstudent = async () => {
    try {
      var res = await _https.get("resources_result_user_get", authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      return res.data;
    } catch {}
  };

  const delete_student_class = async (id: any) => {
    try {
      const res = await _https.get("delete_student_class/" + id, authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      return res.data;
    } catch {}
  };

  const chatgpt_retrieve = async (query: any) => {
    const res = await _https.get("chatgpt_data_get/" + query);
    return res.data;
  };

  const chatgpt_insert = async (data: any) => {
    const res = await _https.post("chatgpt_data_insert", data);
  };

  const get_support_enquiry_data = async () => {
    const res = await _https.get("get_support_enquiry_data");
    return res.data;
  };

  const get_faculty_details_all = async (id: any) => {
    const res = await _https.get("get_faculty_details_all/" + id, authHeader);
    return res.data;
  };


  const get_category_questions = async(id:any)=>{
    try {
      const res = await _https.get("get_course_questions/"+id, authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      return res.data;
    } catch {}
  
  }
  
  const mcq_question_add = async(data:any)=>{
    try {
      const res = await _https.post("mcq_quiz_question_add",data, authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      return res.data;
    } catch {}
  }
  
  const get_quiz_data = async()=>{
    try {
      const res = await _https.get("get_quiz_list", authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      return res.data;
    } catch {}
  }
  
  
  const get_available_quiz = async(id:any)=>{
    try {
      const res = await _https.get("get_live_quiz/"+id, authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      return res.data;
    } catch {}
  }
  
  
  const get_quiz_questions = async(id:any)=>{
    try {
      const res = await _https.get("get_live_quiz_question/"+id, authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      return res.data;
    } catch {}
  }
  
  const submit_quiz_data_from_student = async(data:any)=>{
    try{
      const res = await _https.post("submit_student_quiz_data",data, authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      return res.data;
    }catch{
  
    }
  }
  
  const get_user_quiz_result = async(user_id, mcq_user_id)=>{
    try{
      const res = await _https.get("get_mcq_quiz_answer/"+user_id+"/"+mcq_user_id, authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      return res.data;
    }catch{
  
    }
  }
  
  const all_quiz_data_list = async()=>{
    try{
      const res = await _https.get("get_all_quiz_data", authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      return res.data;
    }catch{
  
    }
  }
  
  const insert_quiz_questions = async(data:any)=>{
    try{
      const res = await _https.post("insert_quiz_questions",data, authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      return res.data;
    }catch{
  
    }
  }
  
  const delete_question_id = async(id:any)=>{
    try{
      const res = await _https.get("delete_quiz_question/"+id, authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      return res.data;
    }catch{
  
    }
  }
  
  const mcq_quiz_del = async(id:any)=>{
  
    try{
      const res = await _https.get("delete_mcq_quiz/"+id, authHeader);
      if (res.data?.msg === "you are not an authorised user") {
        logout();
      }
      return res.data;
    }catch{
  
    } 
  
  }
const get_lesson_course = async(course_id,type)=>{

  try{
    const res = await _https.get("get_lesson_course/"+course_id+"/"+type, authHeader);
    if (res.data?.msg === "you are not an authorised user") {
      logout();
    }
    return res.data;
  }catch{

  } 

}


const retrieve_updated_data = async(user_id)=>{
  try{
    const res = await _https.get("retrieve_user_data/"+user_id, authHeader);
    if (res.data?.msg === "you are not an authorised user") {
      logout();
    }
   // return res.data;
 //  console.log(res.data[0]);
 const allstudentsstored = LocalstorageStudentData();
   var data2 = res.data[0];
    var new_ary = [];
   allstudentsstored.forEach((ck)=>{
    if(ck["student_id"]==data2['student_id']){
    ck = data2;
   
    }
    new_ary.push(ck);
   })
setTimeout(()=>{
  
  localStorage.setItem("get_student_details", btoa(JSON.stringify(new_ary)));
},1000)
   
  }catch{

  } 
}


const get_mcq_student_result_all = async()=>{

  try{
const res = await _https.get("get_mcq_student_result_all",authHeader);
if (res.data?.msg === "you are not an authorised user") {
  logout();
}
return res.data;
  }catch{

  }

}

const add_payment_data = async(add_payment_data)=>{
  try{
    const res = await _https.post("https://cluster0.dataspacesecurity.cloud/service/pdf-mailer/api/v1/submit-invoice",add_payment_data,invoice_auth);
    if (res.data?.msg === "you are not an authorised user") {
      logout();
    }
    return res.data;
      }catch{
    
      }
}

const get_payment_details_by_gen = async(id)=>{
  try{
    const res = await _https.get("get_paid_user_data_by_sales/"+id,authHeader);
    if (res.data?.msg === "you are not an authorised user") {
      logout();
    }
    return res.data;
      }catch{
    
      }
}

const get_payment_details_data = async()=>{
  try{
    const res = await _https.get("get_payment_details_data",authHeader);
    if (res.data?.msg === "you are not an authorised user") {
      logout();
    }
    return res.data;
      }catch{
    
      }
}
const delete_payment_details = async(id,user_id)=>{

  try{
    const res = await _https.get("delete_payment_details/"+id+"/"+user_id,authHeader);
    if (res.data?.msg === "you are not an authorised user") {
      logout();
    }
    return res.data;
      }catch{
    
      }

}

const update_faculty_timing_data = async(data)=>{
  try{
    const res = await _https.post("faculty_update_timing",data,authHeader);
    if (res.data?.msg === "you are not an authorised user") {
      logout();
    }
    return res.data;
      }catch{
    
      }

}

const usermovetojunk = async(user_id)=>{
  try{
    const res = await _https.get("user_move_to_junk/"+user_id,authHeader);
    if (res.data?.msg === "you are not an authorised user") {
      logout();
    }
    return res.data;
  }catch{

  }
}

const get_junk_users = async()=>{
  try{
    const res = await _https.get("get_junk_users",authHeader);
    if (res.data?.msg === "you are not an authorised user") {
      logout();
    }
    return res.data;
  }catch{

  }
}

const undo_junk_user = async(user_id)=>{
  try{
    const res = await _https.get("undo_junk_user/"+user_id,authHeader);
    if (res.data?.msg === "you are not an authorised user") {
      logout();
    }
    return res.data;
  }catch{

  }
}

const usermovetocollege = async(user_id)=>{
  try{
    const res = await _https.get("move_to_college_user/"+user_id,authHeader);
    if (res.data?.msg === "you are not an authorised user") {
      logout();
    }
    return res.data;
  }catch{

  }
}


const get_college_user = async()=>{

  try{
    const res = await _https.get("get_college_users",authHeader);
    if (res.data?.msg === "you are not an authorised user") {
      logout();
    }
    return res.data;
  }catch{

  }

}


const undo_college_user = async(user_id)=>{
  try{
    const res = await _https.get("undo_college_user/"+user_id,authHeader);
    if (res.data?.msg === "you are not an authorised user") {
      logout();
    }
    return res.data;
  }catch{

  }
}


const delete_payment_by_mktr = async(payment_id, user_id)=>{
  try{
    const res = await _https.get("delete_payment_by_mktr/"+payment_id+"/"+user_id,authHeader);
    if (res.data?.msg === "you are not an authorised user") {
      logout();
    }
    return res.data;
  }catch{

  }
}

const save_invoice_data = async(data:any)=>{
  try{
    const res = await _https.post("save_invoice_data",data,authHeader);
    if (res.data?.msg === "you are not an authorised user") {
      logout();
    }
    return res.data;
  }catch{

  }
}

const job_status_change_data = async(data:any, status)=>{

  var ary = {"job_id":data, "status":status}
  try{
    const res = await _https.post("job_status_change",ary,authHeader);
    if (res.data?.msg === "you are not an authorised user") {
      logout();
    }
    return res.data;
  }catch{

  }
}

  return {
    getAllStudents,
    getAllFaculty,
    getAllAdmins,
    getClassSummery,
    getAdminAllReport,
    getAllMarketing,
    getFacultyDetails,
    getAllSales,
    getUserDetails,
    postDeleteFacultyTiming,
    getFacultyTimingAll,
    getAllNews,
    getNotifications,
    getMessages,
    getJobsAll,
    getStudentNotes,
    getStudentMessages,
    getAllStudentsReminder,
    getAllAppliedJobs,
    postUpdateCourseCompleteStatus,
    postUpdateProjectCompleteStatus,
    postAssignProject,
    postAddNewSchedule,

    ///post requests
    getStudentFacultyTiming,
    postAddFacultyTiming,
    postAddMarketing,
    postAddAdmin,
    getStudentClasses,
    postGiveStudentClass,
    deleteOneNews,
    postAddNews,
    postAddMessage,
    deleteJob,
    postUpdateJob,
    postAddFaculty,
    postAddJob,
    postAddNotes,
    updateUserDetails,
    deleteUser,
    deleteStudentClass,
    getFacultyevents,
    get_student_remarks,
    get_faculty_tming,
    get_faculty_upcoming_classes,
    get_faculty_project,
    faculty_project_assign_accepted,
    student_present_absent_data_submit,
    get_student_all_courses,
    getAppliedJob,
    course_add,
    course_update,
    course_delete,
    stud_update_details,
    fac_update_details,
    updateStudentDetails,
    update_student_details,
    getStudentStatus_all,
    getclassreport,
    referredusers,
    branches,
    get_councellors,
    branch_add,
    councellor_add,
    branch_delete,
    councellor_delete,
    get_schedule_list_data,
    delete_available_schedule,
    updateNewSchedule,
    class_complete_faculty,
    addlivelink,
    addrecordedlink,
    upload_class_note_add,
    CourseCompletedStudents,
    get_course_incompleted_student,
    interview_completed,
    student_view_class,
    getresourcesstudent,
    delete_student_class,
    chatgpt_retrieve,
    chatgpt_insert,
    get_support_enquiry_data,
    get_faculty_details_all,
    get_category_questions,
    mcq_question_add,
    get_quiz_data,
    get_available_quiz,
    get_quiz_questions,
    submit_quiz_data_from_student,
    get_user_quiz_result,
    all_quiz_data_list,
    insert_quiz_questions,
    delete_question_id,
    mcq_quiz_del,
    get_lesson_course,
    get_mcq_student_result_all,
    get_student_add_checker,
    retrieve_updated_data,
    add_payment_data,
    get_payment_details_by_gen,
    get_payment_details_data,
    delete_payment_details,
    update_faculty_timing_data,
    usermovetojunk,
    get_junk_users,
    undo_junk_user,
    usermovetocollege,
    get_college_user,
    undo_college_user,
    delete_payment_by_mktr,
    save_invoice_data,
    job_status_change_data
  };
}
