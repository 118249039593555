import { Columns } from "../../../../interfaces/_common";
import globalDataStore from "../../../../store/_globalData";
import React, { useEffect, useState } from "react";
import protectedApiService from "../../../../services/_protected_api";
import PrimeDataTable from "../../../../common/prime_data_table";
import { Link, useLocation } from "react-router-dom";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import userState from "../../../../store/_userState";
export default function Viewallpayment(){
    const { user } = userState();
  
    const {get_payment_details_data, delete_payment_details} = protectedApiService();

const [get_payment, setget_payment] = useState([]);

useEffect(()=>{
    get_payment_details();
},[])


const get_payment_details = async()=>{

    const data = await get_payment_details_data();
    data.forEach(element => {
      console.log(element);
      if(element["due_emi"]!=null){
      var str = element["due_emi"].toString();
      var data2 = str.replace( /(<([^>]+)>)/ig, '');
      element["due_emii"] = data2;
      }else{
        element["due_emii"] = null;
      }
    });
    
    setget_payment(data);
}


const deletepayment_details = async(data)=>{
  //  console.log(data);

    var conf = window.confirm("This is permanent delete. Are you sure to delete?");
    if(conf===true){
        var res = await delete_payment_details(data.payment_receive_id,user.user_id);
        if(res.status==1){
            toast.success("Deleted Successfully");
            get_payment_details();
        }else if(res.status==2){
          toast.error(res.msg);
        }
    }
}

    const tablesStructure: Columns[] = [
        {
            data_name: "bill_no",
            header: "Bill No",
            sortable: true,
            dataFilter: (data: any, key: any) =>
              (
                <>
                  <span className="limited-char">{data[key]}</span>
                </>
              ) || <></>,
          },
        {
          data_name: "name",
          header: "Name",
          sortable: true,
          dataFilter: (data: any, key: any) =>
            (
              <>
                <span className="limited-char">{data[key]}</span>
              </>
            ) || <></>,
        },
        {
            data_name: "email",
            header: "Email",
            sortable: true,
            dataFilter: (data: any, key: any) =>
              (
                <>
                  <span className="limited-char">{data[key]}</span>
                </>
              ) || <></>,
          },
        {
          data_name: "course_name",
          header: "Course Name",
          sortable: true,
          dataFilter: (data: any, key: any) =>
            (
              <>
                <span className="limited-char">{data[key]}</span>
              </>
            ) || <></>,
        },
        {
          data_name: "course_price",
          header: "Course Fees",
          sortable: true,
          dataFilter: (data: any, key: any) =>
            (
              <>
                <span className="limited-char">{data[key]}</span>
              </>
            ) || <></>,
        },
        {
            data_name: "deposite_amount",
            header: "Deposit Amount",
            sortable: true,
            dataFilter: (data: any, key: any) =>
              (
                <>
                  <span className="limited-char">{data[key]}</span>
                </>
              ) || <></>,
          },
          // {
          //   data_name: "due_emi",
          //   header: "Due/Emi Amount",
          //   sortable: true,
          //   dataFilter: (data: any, key: any) =>
          //     (
              
          //       <>
          //         <span className="limited-char" dangerouslySetInnerHTML={{__html: data[key]}}></span>
          //       </>
          //     ) || <></>,
          // },

          {
            data_name: "due_emii",
            header: "Due/Emi Amount",
            sortable: true,
            dataFilter: (data: any, key: any) =>{
             // var data2 = data[key].slice(0,30)+"...";
             
              return(
                <>
                 <span className="limited-char" dangerouslySetInnerHTML={{__html: data[key]}}></span>
                </>
              )
            },
          },
          {
            data_name: "payment_mode",
            header: "Payment Mode",
            sortable: true,
            dataFilter: (data: any, key: any) =>
              (
                <>
                  <span className="limited-char">{data[key]}</span>
                </>
              ) || <></>,
          },
          
          {
            data_name: "next_payment_date",
            header: "Next Payment Date",
            sortable: true,
            dataFilter: (data: any, key: any) =>
              (
                <>
                  <span className="limited-char">{data[key]}</span>
                </>
              ) || <></>,
          },
          {
            data_name: "pdf_invoice",
            header: "Invoice",
            sortable: true,
            dataFilter: (data: any, key: any) =>
              (
                <>
                  <span className="limited-char">
                    {/* <a href={`https://dataspaceacademylearning.com/api/public/fees/${data[key]}`} download target="_blank" className="p-button-rounded p-button-primary p-button-outlined">Download</a> */}
                    <a href={data[key]} download target="_blank" className="p-button-rounded p-button-primary p-button-outlined">Download</a>
                  </span>
                </>
              ) || <></>,
          },
          {
            data_name: "mk_name",
            header: "Received By",
            sortable: true,
            dataFilter: (data: any, key: any) =>
              (
                <>
                  <span className="limited-char">{data[key]}</span>
                </>
              ) || <></>,
          },
          {
            data_name: "created_at",
            header: "Invoice Generate Date & Time",
            sortable: true,
            dataFilter: (data: any, key: any) =>
              (
                <>
                  <span className="limited-char">{data[key]}</span>
                </>
              ) || <></>,
          },
        {
          data_name: "operation",
          header: "Operation",
          sortable: false,
          dataFilter: (data: any, key: any) => {
            
            return (
              <>
               <Button
                  onClick={() => deletepayment_details(data)}
                  icon="pi pi-trash
                    "
                  className="mx-2 p-button-rounded p-button-danger p-button-outlined"
                  aria-label="Delete"
                >Delete</Button>
              </>
            );
            
          },
        },
      ];
    return(
        <>
              <div className="das-exs ">
        <div className="flex-start mx-4">
         {/* <p>View Payment</p> */}
        </div>
      </div>
      <PrimeDataTable
        data={get_payment || []}
        structure={tablesStructure}
        title={"View Payment"}
        onRefresh={get_payment_details}
        newdropdown
      />
        </>
    )
}