import { Columns } from "../../../../interfaces/_common";
import globalDataStore from "../../../../store/_globalData";
import React, { useEffect, useState } from "react";
import protectedApiService from "../../../../services/_protected_api";
import PrimeDataTable from "../../../../common/prime_data_table";
import Loader2 from "../../../../common/loader2";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import LocalstorageStudentData from "../../../../store/_localstorage_student_data";
import StudentAddCheck from "../../../../store/_student_add_check";

export default function AllStudents() {
  const tablesStructure: Columns[] = [
    {
      data_name: "name",
      header: "Name",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "email",
      header: "EmailID",
      sortable: false,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "contact_no",
      header: "Contact",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "course_name",
      header: "Course",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "mode",
      header: "Mode",
      sortable: true,
      dataFilter: (data: any, key: any) => {
        if (data[key] == 1) return <div className="text-success">online</div>;
        if (data[key] == 2) return <div className="text-danger">offline</div>;
        if (data[key] == 3)
          return <div className="text-info">online & offline</div>;
        return <div className="text-gray">Not Specified</div>;
      },
    },
    {
      data_name: "reg_date",
      header: "Register Date & Time",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },

//     {
//       data_name: "options",
//       header: "Action",
//       sortable: true,
//       dataFilter: (data: any, key: any) =>{
//         return(<>
// <Link to="/Home/Students/View Class" state={data}>
//               <Button className="p-btn-primary p-1" aria-label="Facebook">
//                 <i className="pi pi-calendar-plus p-1"></i>
//                 <span className="p-1">View Class</span>
//               </Button>
//             </Link>
//         </>)
//       },
//     },


  ];
  const { allStudents } = globalDataStore();
  const allstudentsstored = LocalstorageStudentData();
  const stu = StudentAddCheck();
  const { getAllStudents } = protectedApiService();
  const [allData, setAllData] = useState<any>(null);
  useEffect(() => {
    // console.log(allstudentsstored);
    getData();
  }, []);

  const getData = async () => {
    if (allStudents?.length) {
      
      setAllData(allStudents);
    } else {
      getFromApi();
    }
  };
  const getFromApi = async () => {
    // const res: any = await getAllStudents();
    // setAllData(res);

    setAllData(allstudentsstored);
  };




  return (
    <>
      {allData ? (
        <PrimeDataTable
          data={allData || []}
          structure={tablesStructure}
          title={"All Students"}
          isForStudent
          onRefresh={getFromApi}
          lazy={true}
        
          totalRecords="625"
          rows={5}
          message
          skillup
          options
          filterDropdown={[{ filter: "course_name", header: "Course Name" }]}
        />



        
      ) : (
        <Loader2 />
      )}
    </>
  );
}
