import { Columns } from "../../../../../interfaces/_common";
import globalDataStore from "../../../../../store/_globalData";
import React, { useEffect, useState } from "react";
import protectedApiService from "../../../../../services/_protected_api";
import PrimeDataTable from "../../../../../common/prime_data_table";
import Loader2 from "../../../../../common/loader2";
import LocalstorageStudentData from "../../../../../store/_localstorage_student_data";
import StudentAddCheck from "../../../../../store/_student_add_check";
export default function InterviewStudents() {
  const tablesStructure: Columns[] = [
    {
      data_name: "name",
      header: "Name",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "email",
      header: "EmailID",
      sortable: false,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "contact_no",
      header: "Contact",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "course_name",
      header: "Course",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "operation",
      header: "Operation",
      sortable: true,
      dataFilter: (data: any, key: any) => {
        return (
          <>
            <button className="btn btn-outline-primary btn-sm">
              Set Placement
            </button>
          </>
        );
      },
    },
  ];
  const { allStudents } = globalDataStore();
  const { getAllStudents, get_junk_users } = protectedApiService();
  StudentAddCheck();
  const allstudentsstored = LocalstorageStudentData();
  
  useEffect(() => {
    getData();
  }, []);

  const [allData, setAllData] = useState<any>(null);
  const getData = async () => {
    // if (allStudents) {
    //   let data: any[] = allStudents.filter((x: any) => {
    //     if (x.interview) {
    //       return x;
    //     }
    //   });
    //   setAllData(data.length ? [...data] : []);
    // } else {
    //   getFromApi();
    // }
    getFromApi();
  };

  const remove_junk_data = (element,all_data)=>{
    const n_ary:any = [];
   // console.log(all_data);
    all_data.forEach((ll)=>{
      //console.log(ll["user_id"]);
      if(parseInt(ll["user_id"])!=element){
       n_ary.push(ll);
      }
    })
  
    return n_ary;
  }

  const getFromApi = async () => {
    //allstudentsstored
    // const res: any = await getAllStudents();
     const junk = await get_junk_users();

 //  console.log(junk);
var new_ary = [];
var all_data:any = allstudentsstored;

junk.forEach(element => {
  //console.log(all_data);
all_data = remove_junk_data(element,all_data);

 // console.log(element);

});


const map = new Map();
const stored_data = [];
    for (const item of all_data) {
        if (!map.has(item.user_id)) {
            map.set(item.user_id, true);    // set any value to Map
            stored_data.push(item);


        }
    }  

    //allstudentsstored
    // const res: any = await getAllStudents();
    const res: any = stored_data;
    let data: any[] = res.filter((x: any) => {
      if (x.interview) {
        return x;
      }
    });
    setAllData(data.length ? [...data] : []);
  };

  return (
    <>
      {allData ? (
        <PrimeDataTable
          data={allData}
          structure={tablesStructure}
          title={"Interview Completed"}
          isForStudent
          onRefresh={getFromApi}
          note
          message
          options
          timeline
          view
          filterDropdown={[{ filter: "course_name", header: "Course Name" }]}
        />
      ) : (
        <Loader2 />
      )}
    </>
  );
}
