import { Columns } from "../../../../../interfaces/_common";
import globalDataStore from "../../../../../store/_globalData";
import React, { useEffect, useState } from "react";
import protectedApiService from "../../../../../services/_protected_api";
import PrimeDataTable from "../../../../../common/prime_data_table";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import Loader2 from "../../../../../common/loader2";
import LocalstorageStudentData from "../../../../../store/_localstorage_student_data";
import { toast } from "react-toastify";
export default function Junkusers() {
  const tablesStructure: Columns[] = [
    {
      data_name: "name",
      header: "Name",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "email",
      header: "EmailID",
      sortable: false,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "contact_no",
      header: "Contact",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "course_name",
      header: "Course",
      sortable: true,
      dataFilter: (data: any, key: any) => data[key] || <></>,
    },
    {
      data_name: "operation",
      header: "Operation",
      sortable: true,
      dataFilter: (data: any, key: any) => {
        return (
          <>
          
              <Button className="p-btn-primary p-1" aria-label="Facebook" onClick={()=>{undo_junk_users(data)}}>
               
                <span className="p-1">Undo</span>
              </Button>
           
          </>
        );
      },
    },
  ];
  const { allStudents, setAllStudents } = globalDataStore();
  const { getAllStudents, get_junk_users, undo_junk_user } = protectedApiService();
  const allstudentsstored = LocalstorageStudentData();
  useEffect(() => {
    getData();
  }, []);

  const [allData, setAllData] = useState<any>(null);
  const getData = async () => {
    // if (allStudents) {
    //   let data: any[] = allStudents.filter((x: any) => {
    //     if (!x.assign_class && !x.assign_teacher && x.course_id) {
    //       return x;
    //     }
    //   });
    //   setAllData(data.length ? [...data] : []);
    // } else {
    //   getFromApi();
    // }
    getFromApi();
  };



  const getFromApi = async () => {


    const junk = await get_junk_users();

 //  console.log(junk);
var new_ary = [];
//var all_data:any = allstudentsstored;

junk.forEach(element => {
  //console.log(all_data);
  allstudentsstored.forEach((ll)=>{
    //console.log(ll["user_id"]);
    if(parseInt(ll["user_id"])==element){
        new_ary.push(ll);
    }
  })

 // console.log(element);

});


//console.log(new_ary);

const map = new Map();
const stored_data = [];
    for (const item of new_ary) {
        if (!map.has(item.user_id)) {
            map.set(item.user_id, true);    // set any value to Map
            stored_data.push(item);


        }
    }  

    //allstudentsstored
    // const res: any = await getAllStudents();
    const res: any = stored_data;
    
    setAllData(res);
    // console.log("not_schedule", data);
  
  };


  const undo_junk_users = async(user)=>{
    const conf = window.confirm("Are you sure?");
    if(conf==true){
    var data = await undo_junk_user(user.user_id);
    if(data==1){
        toast.success("Users moved");
        getFromApi();

    }
  }
  }

  return (
    <>
      {allData ? (
        <PrimeDataTable
          data={allData}
          structure={tablesStructure}
          title={"Junk Users"}
          
          onRefresh={getFromApi}
         />
      ) : (
        <Loader2 />
      )}
    </>
  );
}
